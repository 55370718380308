export default {
    methods: {
        getNextSibling(elem, selectors) {
            // Get the next sibling element
            if (!selectors) return sibling;
            var sibling = null;
            
            if(elem.children.length) {
                sibling = elem.firstElementChild;
            } else {
                sibling = elem.nextElementSibling;

                // If there's no sibling, return to the parent
                if (!sibling && elem.parentElement) {

                    // If the parent are focus page, Stop.
                    if(elem.parentElement.matches('.focus-page')){
                        return false;
                    } else {
                        var parentNodeElement = elem.parentElement;
                        var LookingNextParentElement = true;

                        while(LookingNextParentElement) {
                            if(parentNodeElement && parentNodeElement.nextElementSibling) {
                                sibling = parentNodeElement.nextElementSibling;
                                LookingNextParentElement = false;
                            } else {
                                parentNodeElement = parentNodeElement.parentNode;
                            }                                
                        }
                    }
                }
            }

            // Check sibling matches
            if (sibling.matches(selectors)) {
                return sibling;
            } else {
                // Start recursivity next element
                return this.getNextSibling(sibling, selectors);
            }
        },
        getPreviousSibling(elem, selectors, getCurrent = false) {
            // Get the next sibling element
            var sibling = getCurrent ? elem : elem.previousElementSibling;

            if(sibling && sibling.matches(selectors)) {
                return sibling;
            } else if (sibling && sibling.children.length) {
                return this.getPreviousSibling(sibling.lastElementChild, selectors, true);
            } else if (sibling && sibling.previousElementSibling) {
                return this.getPreviousSibling(sibling, selectors);
            }else if(elem.parentElement) {
                if(elem.parentElement.matches('.focus-page')){
                    return false;
                } else {
                    var parentNodeElement = elem.parentElement;
                    var LookingPrevParentElement = true;

                    while(LookingPrevParentElement) {
                        if(parentNodeElement.previousElementSibling) {
                            sibling = parentNodeElement;
                            LookingPrevParentElement = false;
                        } else {
                            parentNodeElement = parentNodeElement.parentNode
                        }                                
                    }
                }
            }

            return this.getPreviousSibling(sibling, selectors);
        },
        getCaretCoordinates(parentID) {
            let x, y;

            let parentDiv2 = document.querySelector(`#row-id-${parentID}`);
            let selectedRange2 = window.getSelection().getRangeAt(0);

            // Get the position of the selected text relative to the parent div
            let rect2 = selectedRange2.getBoundingClientRect();
            y = rect2.top - parentDiv2.getBoundingClientRect().top;
            x = rect2.left - parentDiv2.getBoundingClientRect().left;
            
            return { x, y };
        },
        getSelection(element) {
            let selectionStart, selectionEnd;
            const isSupported = typeof window.getSelection !== "undefined";
            if (isSupported) {
                const range = window.getSelection().getRangeAt(0);
                const preSelectionRange = range.cloneRange();
                preSelectionRange.selectNodeContents(element);
                preSelectionRange.setEnd(range.startContainer, range.startOffset);
                selectionStart = preSelectionRange.toString().length;
                selectionEnd = selectionStart + range.toString().length;
            }
            return { selectionStart, selectionEnd };
        }
    },
};
